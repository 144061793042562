<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="visiable"
    :before-close="handleClose"
  >
    <el-form
      ref="form"
      :rules="rules"
      :model="form"
      size="small"
      label-position="top"
    >
      <el-form-item label="平台" prop="source">
        <el-select
          v-model="form.source"
          placeholder="请选择"
          @change="modulelist"
        >
          <el-option :value="1" label="Shopee平台"></el-option>
          <el-option :value="2" label="Lazada平台"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品类型" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio-button :label="1">正常套餐</el-radio-button>
          <el-radio-button :label="2">加油包</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="商品名称" prop="goods_name">
        <el-input
          v-model="form.goods_name"
          placeholder="请输入商品名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品价格" prop="price">
        <el-input-number
          v-model="form.price"
          label="请输入商品价格"
          :min="0"
          :precision="2"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="使用天数" prop="effect_day">
        <el-input-number
          v-model="form.effect_day"
          label="请输入使用天数"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="赠送天数" prop="give_day">
        <el-input-number
          v-model="form.give_day"
          label="请输入赠送天数"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="是否活动" prop="is_activity">
        <el-switch
          v-model="form.is_activity"
          active-text="活动"
          inactive-text="非活动"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="商品描述" prop="type">
        <el-input
          v-model="form.content"
          type="textarea"
          :rows="3"
          placeholder="请输入商品描述"
        ></el-input>
      </el-form-item>
      <el-form-item label="关联模块" class="myview">
        <el-checkbox-group v-model="form.goods_items">
          <el-checkbox
            border
            v-for="(m, mi) in cond.moduleList"
            :label="m.id"
            :key="mi"
            >{{ m.module_name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="form.loading" @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      title: "添加套餐",
      visiable: false,
      form: this.getDefaultData(),
      cond: {
        moduleList: [],
      },
      rules: {
        goods_name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        effect_day: [
          {
            validator: (rule, value, callback) => {
              if (value == "" || value == 0) {
                return callback(new Error("请输入使用天数"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
      url: {
        goodsadd: "/sysadminoperate/goodsadd",
        modulelist: "/sysadminsystem/modulelist",
      },
    };
  },
  created() {},
  methods: {
    getDefaultData: function () {
      return {
        id: 0,
        goods_name: "",
        content: "",
        source: 1,
        type: 1,
        effect_day: 0,
        give_day: 0,
        is_activity: 0,
        price: 0.0,
        goods_items: [],
        old_goods_items:[],
        loading: false,
      };
    },
    handleClose: function () {
      this.$refs.form.resetFields();
      this.visiable = false;
    },
    show: function (row) {
      if (row) {
        this.title = "编辑套餐";
        this.form = this.$func.copy(row);
        this.form.old_goods_items=this.$func.copy(this.form.goods_items);
      } else {
        this.title = "添加套餐";
        this.form = this.getDefaultData();
      }
      this.modulelist();
      this.visiable = true;
    },
    inmodulelist: function (id) {
      for (var i in this.cond.moduleList) {
        if (this.cond.moduleList[i].id == id) {
          return true;
        }
      }
      return false;
    },
    modulelist: function () {
      this.$rq
        .postAction(this.url.modulelist, {
          source: this.form.source,
          page: 1,
          pageSize: 1000,
        })
        .then((rs) => {
          if (rs.code == 1) {
            this.cond.moduleList = [];
            for (var i in rs.list) {
              this.cond.moduleList.push(rs.list[i]);
            }
            if (this.form.old_goods_items&&this.form.old_goods_items.length>0) {
              try {
                var items = JSON.parse(this.form.old_goods_items);
                this.form.goods_items = [];
                for (var j in items) {
                  if (
                    this.inmodulelist(parseInt(items[j].relation_module_id))
                  ) {
                    this.form.goods_items.push(
                      parseInt(items[j].relation_module_id)
                    );
                  }
                }
                this.$func.log(this.form);
              } catch (e) {
                this.$func.log(e);
              }
            }
          }
        });
    },
    submit: function () {
      this.$refs.form.validate((v) => {
        if (v) {
          this.form.loading = true;
          this.$rq.postAction(this.url.goodsadd, this.form).then((rs) => {
            this.form.loading = false;
            if (rs.code == 1) {
              this.visiable = false;
              this.$message({
                type: "success",
                message: rs.msg,
              });
              this.$emit("refresh");
            } else {
              this.$message({
                type: "warning",
                message: rs.msg,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.myview >>> .el-checkbox {
  flex: 1;
  margin-left: 10px;
  margin-bottom: 8px;
}
.myview >>> .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
</style>