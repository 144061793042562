<template>
  <Cond :where="where" @refresh="refresh">
    <div class="mgb10 text-right">
      <el-button
        @click="openmealform()"
        type="primary"
        icon="el-icon-edit"
        size="small"
        >添加套餐</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      size="small"
      v-loading="where.loading"
    >
      <el-table-column prop="goods_name" label="套餐商品名"> </el-table-column>
      <el-table-column prop="price" label="价格" width="180"> </el-table-column>
      <el-table-column prop="effect_day" label="使用天数" width="100">
      </el-table-column>
      <el-table-column prop="source" label="平台" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.source == 1">
            <el-tag type="primary" effect="dark" size="small">SHOPEE</el-tag>
          </div>
          <div v-else-if="scope.row.source == 2">
            <el-tag type="success" effect="dark" size="small">LAZADA</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="描述" width="180">
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="180">
        <template slot-scope="scope">
          {{ $func.format(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 0">
            <el-tag type="primary" effect="dark" size="small">下架</el-tag>
          </div>
          <div v-else-if="scope.row.status == 1">
            <el-tag type="success" effect="dark" size="small">上架</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 0">
            <el-button @click="goodsupper(scope.row)" type="text" size="small"
              >上架</el-button
            >
          </div>
          <div v-else>
            <el-button @click="goodsupper(scope.row)" type="text" size="small"
              >下架</el-button
            >
          </div>
          <div>
            <el-button @click="openmealform(scope.row)" type="text" size="small"
              >编辑</el-button
            >
          </div>
          <div>
            <el-popconfirm
              title="确定要删除吗？"
              @confirm="goodsdelete(scope.row)"
            >
              <el-button type="text" size="small" slot="reference"
                >删除</el-button
              >
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mgt10 text-center">
      <el-pagination
        @current-change="loadList"
        :current-page.sync="where.page"
        background
        layout="prev, pager, next"
        :page-size="where.pageSize"
        :total="where.total"
      >
      </el-pagination>
    </div>
    <Mealform ref="mealform" @refresh="loadList"></Mealform>
  </Cond>
</template>
<script>
import Cond from "./setmealcond.vue";
import Mealform from "@/components/backend/operate/mealform.vue";
export default {
  components: {
    Cond,
    Mealform,
  },
  data() {
    return {
      url: {
        goodslist: "/sysadminoperate/goodslist",
        goodsdelete: "/sysadminoperate/goodsdelete",
        goodsupper: "/sysadminoperate/goodsupper",
      },
      where: {
        source: 0,
        goods_name: "",
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false,
      },
      tableData: [],
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    openmealform: function (row) {
      this.$refs.mealform.show(row);
    },
    refresh: function () {
      this.where.page = 1;
      this.loadList();
    },
    loadList: function () {
      this.where.loading = true;
      this.$rq.postAction(this.url.goodslist, this.where).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.tableData = [];
          for (var i in rs.list) {
            this.tableData.push(rs.list[i]);
          }
          this.where.total = parseInt(rs.pageInfo.total);
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
    goodsupper: function (row) {
      this.where.loading = true;
      this.$rq.postAction(this.url.goodsupper, { id: row.id }).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.loadList();
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
    goodsdelete: function (row) {
      this.where.loading = true;
      this.$rq.postAction(this.url.goodsdelete, { id: row.id }).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.loadList();
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
  },
};
</script>